/// <reference path="o365.pwa.declaration.sw.routehandlers.api.context.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    function contextRouteHandler() {
        self.o365.registerRoute(
            new RegExp('\/user\/orgunitcontext\/*'),
            new self.workbox.strategies.NetworkOnly(),
            'POST'
        );
    }

    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.routehandlers.context.d.ts')>({ contextRouteHandler });
})();
